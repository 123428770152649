import React from "react";
import { useParams } from "react-router-dom";
import styles from "../assets/styles/style";
import { academicDegrees } from "../constants/educativeOfferData.js";

const normalizeString = (str) => {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "-");
};

const EducativeOfferDetail = () => {
  const { courseName } = useParams();

  const foundItem = academicDegrees.find(
    (academicDegree) =>
      normalizeString(academicDegree.name) === normalizeString(courseName)
  );

  if (foundItem) {
    return (
        <>
          <div className="w-full overflow-hidden text-justify">
            <div className={`bg-[#333] text-white ${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <section
                    className={`flex flex-wrap flex flex-col ${styles.paddingY}`}
                >
                  <div className={`flex-1 flex-col xl:px-0 sm:px-16 px-6`}>
                    <p className="uk-badge mb-3">100% Online</p>
                    <h1 className="text-4xl md:mb-[15px] xs:mb-[10px]">
                      <strong>{foundItem.nameInPage}</strong>
                    </h1>
                    <p className="md:mb-[15px] xs:mb-[10px]">
                      {foundItem.introductionToTheProgram}
                    </p>
                    <hr className="border-t-4 border-t-primary w-[104px] mt-[25px]"/>
                    <div className="justify-between items-end flex flex-wrap">
                      <div>
                        <div>
                          <div>
                            {foundItem.type !== "Diplomado" ? (<p className="font-bold">
                              RVOE:
                            </p>) : null}
                            <p>
                            <span>{foundItem.rvoe}
                              {foundItem.rvoeLink && (
                                  <a href={foundItem.rvoeLink} target="_blank" rel="noopener noreferrer"
                                     className="button-red">Consulta aquí</a>)}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="items-center flex flex-wrap">
                          <div>
                            <div>
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 20 20"
                                  width="24"
                                  height="24"
                              >
                                <path
                                    fill="none"
                                    stroke="#000"
                                    d="M2.4,3.8C1.6,3.8,1,4.4,1,5.2v12.5C1,18.4,1.6,19,2.4,19l0,0h15.2c0.8,0,1.4-0.6,1.4-1.4l0,0V5.2 c0-0.8-0.6-1.4-1.4-1.4h-2.8"
                                ></path>
                                <line
                                    fill="none"
                                    stroke="#000"
                                    x1="5.2"
                                    y1="1"
                                    x2="5.2"
                                    y2="6.5"
                                ></line>
                                <line
                                    fill="none"
                                    stroke="#000"
                                    x1="14.8"
                                    y1="1"
                                    x2="14.8"
                                    y2="6.5"
                                ></line>
                                <line
                                    fill="none"
                                    stroke="#000"
                                    x1="5.2"
                                    y1="3.8"
                                    x2="12.1"
                                    y2="3.8"
                                ></line>
                                <circle
                                    fill="#ffffff"
                                    stroke="#ffffff"
                                    cx="5.2"
                                    cy="10.7"
                                    r="0.7"
                                ></circle>
                                <circle
                                    fill="#ffffff"
                                    stroke="#ffffff"
                                    cx="10"
                                    cy="10.7"
                                    r="0.7"
                                ></circle>
                                <circle
                                    fill="#ffffff"
                                    stroke="#ffffff"
                                    cx="14.8"
                                    cy="10.7"
                                    r="0.7"
                                ></circle>
                                <circle
                                    fill="#ffffff"
                                    stroke="#ffffff"
                                    cx="5.2"
                                    cy="14.8"
                                    r="0.7"
                                ></circle>
                              </svg>
                            </div>
                          </div>
                          <div className="pl-[15px]">
                            <p>{foundItem.duration}</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="items-center flex flex-wrap">
                          <div>
                            <div>
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 20 20"
                                  width="24"
                                  height="24"
                              >
                                <path
                                    fill="none"
                                    stroke="#000"
                                    d="M2.1,0.9h2.6c0.6,0,1.1,0.5,1.1,1.1v15.9c0,0.6-0.5,1.1-1.1,1.1H2.1c-0.6,0-1.1-0.5-1.1-1.1 V2.1C1,1.4,1.5,0.9,2.1,0.9z"
                                ></path>
                                <path
                                    fill="none"
                                    stroke="#000"
                                    d="M7,3.7h2.6c0.6,0,1.1,0.5,1.1,1.1v13.1c0,0.6-0.5,1.1-1.1,1.1H7c-0.6,0-1.1-0.5-1.1-1.1V4.9 C5.9,4.2,6.4,3.7,7,3.7z"
                                ></path>
                                <path
                                    fill="none"
                                    stroke="#ff5900"
                                    d="M12.5,3.9l1.9-0.5c0.6-0.2,1.2,0.2,1.4,0.8L18.9,17c0.2,0.6-0.2,1.2-0.8,1.4l-1.9,0.5 C15.6,19,15,18.6,14.8,18L11.6,5.3C11.5,4.7,11.9,4.1,12.5,3.9z"
                                ></path>
                                <line
                                    fill="none"
                                    stroke="#000"
                                    x1="1"
                                    y1="14.2"
                                    x2="5.9"
                                    y2="14.2"
                                ></line>
                                <line
                                    fill="none"
                                    stroke="#000"
                                    x1="5.9"
                                    y1="12.8"
                                    x2="10.8"
                                    y2="12.8"
                                ></line>
                                <line
                                    fill="none"
                                    stroke="#ff5900"
                                    x1="14.3"
                                    y1="15.6"
                                    x2="18.3"
                                    y2="14.6"
                                ></line>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <p className="pl-[15px]">
                              {foundItem.modules} módulos
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          {foundItem.videoLink !== "" ? (<div className="flex items-center justify-center pb-10 pt-10">
              <iframe
                  width="560"
                  height="315"
                  src={foundItem.videoLink}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
              ></iframe>
            </div>) : null }

          <div className="w-full overflow-hidden mt-5 text-justify">
            <div className={`bg-dimWhite ${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <section className={`flex flex-wrap flex flex-col`}>
                  <div className={`flex-1 flex-col xl:px-0 sm:px-16 px-6`}>
                    <div className="flex flex-wrap">
                      <div>
                        {foundItem.objetive !== "" ? (<h2 className="text-2xl">
                          <strong>Objetivo:</strong>
                        </h2>) : null}
                        <div className="v-line-primary mb-10">
                          <p>{foundItem.objetive}</p>
                        </div>

                        <div className="mb-10">
                          <h2 className="text-2xl">
                            <strong>{foundItem.question}</strong>
                          </h2>

                          {foundItem.why.inline !== "" && (
                              <p>{foundItem.why.inline}</p>
                          )}
                          {foundItem.why.highlights.length > 0 && (
                              <ul className="uk-list-disc mb-[15px] space-y-2 mt-2">
                                {foundItem.why.highlights.map(
                                    (highlight, index) => (
                                        <li
                                            key={index}
                                            className="pl-[60px] text-jusitify"
                                        >
                                          {highlight}
                                        </li>
                                    )
                                )}
                              </ul>
                          )}
                        </div>

                        <div className="mb-10">
                          {foundItem.admissionProfile === "" ? (<h2 className="text-2xl">
                            <strong>Perfil del aspirante:</strong>
                          </h2>) : null}
                          {foundItem.admissionProfile.inline !== "" && (
                              <p>{foundItem.admissionProfile.inline}</p>
                          )}
                          {foundItem.admissionProfile.highlights.length > 0 && (
                              <ul className="uk-list-disc mb-[15px] space-y-2 mt-2">
                                {foundItem.admissionProfile.highlights.map(
                                    (highlight, index) => (
                                        <li
                                            key={index}
                                            className="pl-[60px] text-jusitify"
                                        >
                                          {highlight}
                                        </li>
                                    )
                                )}
                              </ul>
                          )}
                        </div>

                        <div className="mb-10">
                          {foundItem.admissionProfile === "" ? (<h2 className="text-2xl">
                            <strong>Perfil del egresado:</strong>
                          </h2>) : null}
                          {foundItem.graduationProfile.inline !== "" && (
                              <p>{foundItem.graduationProfile.inline}</p>
                          )}
                          {foundItem.graduationProfile.highlights.length > 0 && (
                              <ul className="uk-list-disc mb-[15px]  space-y-2 mt-2">
                                {foundItem.graduationProfile.highlights.map(
                                    (highlight, index) => (
                                        <li
                                            key={index}
                                            className="pl-[60px] text-jusitify"
                                        >
                                          {highlight}
                                        </li>
                                    )
                                )}
                              </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </>
    );
  }
};

export default EducativeOfferDetail;
