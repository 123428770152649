import React from "react";
import Heading from "./common/Heading.jsx";
import { homeAbout } from "../constants/dummydata.js";
import Awrapper from "./Awrapper.jsx";
import img from "../assets/images/main/about.webp";

const AboutCard = () => {
  return (
    <>
      <section className="aboutHome">
        <div className="mx-auto max-w-[100%] flex flex-col md:flex-row justify-between items-stretch">
          <div className="left w-full md:w-1/2">
            <img src={img} alt="" className="object-cover w-full h-full" />
          </div>
          <div className="right w-full md:w-1/2 p-8">
            <Heading
              subtitle="La era digital"
              title="Ventajas de estudiar en línea"
            />
            <div className="mt-8 flex flex-col">
              {homeAbout.map((val, index) => (
                <div
                  key={index}
                  className="mt-6 p-4 transition duration-500 cursor-pointer shadow-md mb-6"
                >
                  <div className="flex items-center">
                    <div className="img">
                      <img
                        src={val.cover}
                        alt=""
                        className="w-[100px] h-[50px] object-contain"
                      />
                    </div>
                    <div className="text ml-4">
                      <h2 className="text-lg mb-2">{val.title}</h2>
                      <p className="text-gray-600">{val.desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Awrapper />
    </>
  );
};

export default AboutCard;
