import React from "react";
import { awrapper } from "../constants/dummydata.js";
import backgroundImage from "../assets/images/main/awrapper.webp";

const Awrapper = () => {
  return (
    <section
      className="py-6 md:py-8 bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="mx-auto max-w-[85%] mx-auto grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-10">
        {awrapper.map((val, index) => (
          <div
            key={index}
            className="bg-white bg-opacity-0 p-2 md:p-4 rounded-lg flex flex-col justify-between items-center"
          >
            <div className="img mb-2">
              <img
                src={val.cover}
                alt={val.title}
                className="w-14 h-14 md:w-24 md:h-24 object-cover filter brightness-0 opacity-100"
              />
            </div>
            <div className="text text-center">
              <h1 className="text-md md:text-lg font-bold mb-1 opacity-100 mb-[-5px]">
                {val.data}
              </h1>
              <h3 className="text-sm md:text-base opacity-100">{val.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Awrapper;
