import React from "react"

const Heading = ({ subtitle, title }) => {
  return (
    <>
      <div className='text-center'>
        <h3 className="font-semibold tracking-wide text-primary uppercase">{subtitle} </h3>
        <h1 className="text-2xl my-3 capitalize">{title} </h1>
      </div>
    </>
  )
}

export default Heading
