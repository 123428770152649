import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <section className="hero bg-cover bg-fixed top-0 left-0 z-[-1] w-full h-screen pr-50 text-white pt-[5%] xl:pt-[10%]">
        <div className="mx-auto max-w-[85%]">
          <div className="w-1/2 text-highlight">
            <h3 className="text-4xl font-bold mb-2">Bienvenido a </h3>
            <h1 className="text-4xl mb-8 font-bold text-white">
              INSTITUTO DE CAPACITACIÓN Y APRENDIZAJE PERMANENTE
            </h1>
            <p className="text-2xl"> Te acompañamos para que logres más.</p>
            <div className="button">
              <Link to="contacto">
                <button className="bg-primary text-white">
                  CONTÁCTANOS <i className="fa fa-long-arrow-alt-right"></i>
                </button>
              </Link>
              <a href="https://incap.edu.mx/aula-virtual/login/index.php">
                <button>
                  ¿Eres alumno? ¡Ve al aula virtual!
                  <i className="fa fa-long-arrow-alt-right"></i>
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
